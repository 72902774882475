body {
	overflow-y: hidden;
}
/* This will apply to all scrollbars in your application */
::-webkit-scrollbar {
	width: 10px; /* Adjust width of scrollbar */
}

::-webkit-scrollbar-track {
	background: var(
		--baseColor
	); /* Set the track (background) color to the base color of the theme */
}

::-webkit-scrollbar-thumb {
	background: var(
		--primaryColor
	); /* Set the thumb (moving part) color to the primary color of the theme */
	border-radius: 5px; /* Optional: Add border radius to make the thumb rounder */
}

::-webkit-scrollbar-thumb:hover {
	background: var(--textColor); /* Change color of thumb when hovering */
}
